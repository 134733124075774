export default {
    state: {
        menu: [
            { product: true, title: 'Earnouts', bgcolor: 'ctransaction' },
            { button: true, title: 'Create Project', href: '/earnout/project#add' },

            // {
            //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
            // },
            {
                title: 'Projects', icon: 'mdi mdi-format-list-group', href: '/earnout/project',
            },
        ],
    },
    mutations: {
    },
    actions: {
    },
    getters: {

    },
}